<template>
  <div
    class="pa-0"
    style="background-color: transparent; width: 100%; height: 100%"
  >
    <div style="width: 100%; height: 80vh" class="overflow-y-auto">
      <v-progress-linear
        v-if="conversationsLoading"
        color="primary"
        indeterminate
      />
      <List v-else />
    </div>
  </div>
</template>

<script>
import { messages, auth } from '../../sharedPlugin';
import List from './List.vue';

export default {
  props: ['isCloseShow'],
  emits: ['newMessage', 'messageList'],
  components: { List },
  computed: {
    ...messages.mapComputed([
      'conversationsLoading',
      'messageGroups',
      'messageGroupsLoading'
    ]),
    ...auth.mapComputed(['user'])
  },
  methods: {
    newMessage() {
      this.deselectConversation();
      this.$emit('newMessage');
    }
  },
  mounted() {}
};
</script>

<style scoped>
.overflow {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.v-toolbar-title {
  align-self: center;
}

.scrollCol {
  overflow: auto;
}
</style>
