<template>
  <div style="background-color: white">
    <initialization-check>
      <v-sheet class="msg">
        <Conversations
          @newMessage="createMessage"
          @close="isShown = false"
          :isCloseShow="isCloseShow"
        />
      </v-sheet>
    </initialization-check>
  </div>
</template>

<script>
import { messages, auth, supportChat } from '../../sharedPlugin';
import Conversations from './Conversations.vue';
import InitializationCheck from '../messages/InitializationCheck.vue';

export default {
  components: {
    Conversations,
    InitializationCheck
  },
  data: () => ({
    creatingMessage: false,
    showBrookebot: false,
    isCloseShow: false
  }),
  computed: {
    ...messages.mapComputed([
      'shown',
      'totalUnreadCount',
      'selectedConversation'
    ]),
    ...auth.mapComputed(['user']),
    isShown: {
      get() {
        return this.shown;
      },
      set(val) {
        this.setShown(val);
      }
    }
  },
  methods: {
    // Global
    ...supportChat.conversations.mapMethods(['setSelectedConversation']),
    ...messages.mapMethods(['setShown']),
    clearMessage() {
      this.showBrookebot = false;
      this.creatingMessage = false;
    },
    createMessage() {
      if (this.user.features.brookebot) {
        this.showBrookebot = true;
      }
      this.creatingMessage = true;
    },
    brookebotFinish() {
      this.showBrookebot = false;
    },
    newConversation() {
      this.setSelectedConversation(null);
      this.$emit('newConversation');
    }
  }
};
</script>
<style scoped>
.v-menu__content {
  overflow: hidden;
}
</style>
<style>
.body {
  height: 100%;
}

.msg {
  width: 100%;
  height: 550px;
}
</style>
