<template>
  <div id="support-chat">
    <ConversationsList
      style="height: 80vh"
      @newConversation="dialogConversationForm(true)"
    />

    <v-footer
      height="60px"
      style="
        background-color: transparent;
        margin-bottom: 5px;
        width: 100%;
        justify-content: center;
        position: absolute;
        bottom: 0px;
      "
    >
      <Btn theme="primary" width="90%" @click="goToNewMessage">New Message</Btn>
    </v-footer>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ConversationsList from '../../components/supportChat/ConversationsList.vue';

export default {
  name: 'SupportChatScreen',
  components: {
    ConversationsList
  },
  data: () => ({
    creatingConversation: false
  }),
  computed: {
    // Global
    ...mapState('supportChat/conversations', {
      selectedConversation: 'selected'
    })
  },
  methods: {
    dialogConversationForm(status) {
      this.creatingConversation = status;
    },
    goToNewMessage() {
      this.$router.push({ path: '/messages/new' });
    }
  }
};
</script>

<style lang="scss">
#support-chat {
  background: #fff;
  height: 100%;
  .v-menu__content {
    overflow: hidden;
  }

  .msg {
    width: 100%;
    height: 550px;
  }
}
</style>
