<template>
  <div style="display: contents">
    <div v-if="messagingStatus.success" style="display: contents">
      <slot />
    </div>
    <div v-else-if="messagingStatus === false">
      <v-progress-circular />
    </div>
    <div v-else>
      <v-card>
        <v-card-text>
          <v-alert text type="error">{{ messagingStatus.message }}</v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="messagingStatus.retryable" @click="initializeMessaging">
            Request permissions
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  computed: {
    ...mapState(['messagingStatus'])
  },
  methods: {
    ...mapActions(['initializeMessaging'])
  }
};
</script>
